<!--
 * @Author: zoujp
 * @Date: 2020-11-19 11:17:15
 * @LastEditTime: 2024-03-12 15:47:54
 * @LastEditors: Please set LastEditors
 * @Description: 主题列表视图
 * @FilePath: \user\src\views\themeSet\viewDefault\index.vue
-->
<template>
  <div class="theme-set" :id="'theme' + viewId" v-en-loading="enloading">
    <div class="topbar" v-if="!refData">
      <div class="pull-left">
        <themeViewComm
          :templateId="templateId"
          :viewId="viewId"
          :templateName="templateName"
        ></themeViewComm>
        <!-- 按钮式可左右滑动页签组 -->
        <div class="button-container">
          <div class="left-arrow" v-if="tabList.length > 4" @click="moveLeft">
            <en-icon
              name="iconjiantou-gengduo"
              size="14px"
              class="arr-icon"
              style="color: #aab6c7"
            ></en-icon>
          </div>
          <div class="content" ref="content">
            <div
              class="inner"
              ref="inner"
              :style="{ transform: `translateX(${transPosition}px)` }"
            >
              <button
                v-for="(item, index) in tabList"
                :key="index"
                :class="{
                  'tab-filter-button': true,
                  active: activeIndex === index,
                }"
                @click="changeTabs(item, index)"
              >
                {{ item.name }}
              </button>
            </div>
          </div>
          <div class="right-arrow" v-if="tabList.length > 4" @click="moveRight">
            <en-icon
              name="iconjiantou-gengduo"
              size="14px"
              class="right-icon"
              style="color: #aab6c7; transform: rotate(180deg)"
            ></en-icon>
          </div>
        </div>
      </div>

      <div class="pull-right">
        <btnComm
          :btnList="batchBtnList"
          :templateId="templateId"
          @filterChange="filterChange"
          @compareChange="compareChange"
          @exportChange="exportChange"
        ></btnComm>
      </div>
      <!-- <div class="pull-center">
        <shareComm :templateName="templateName" :templateId="templateId" @saveCallback="saveCallback"></shareComm>
      </div> -->
    </div>
    <div class="centbar">
      <!-- <div class="view-default">
        <en-tabs :list="tabList" type="white" v-model="activeTab" @change="changeTabs"> </en-tabs>
      </div> -->
      <div class="view-table">
        <!-- 重构v2 去除 -->
        <!-- <div class="taglis" v-if="conditions.length">
          <el-tag v-for="tag in conditions" :key="tag.name" closable @close="tagClose(tag)" :type="tag.info"> {{ tag.fieldName }}:{{ tag.valueText||"为空" }} </el-tag>
        </div> -->
        <div class="table-cent">
          <en-table
            ref="viewTable"
            :key="refData ? 'refData' + viewId : viewId"
            :data="defaultList"
            :hover-row-config="{
              dataMethod: buttonData,
              changeMethod: buttonClick,
            }"
            :page-config="{
              pageModel: pageModel,
              changeMethod: handlePageChanged,
            }"
            height="100%"
            :height-config="refData ? {} : { bottomHeight: 10 }"
            @filter-sort-change="handleFilterSortChange"
            @row-click="handleRowClick"
          >
            <en-table-column type="index" width="60px"></en-table-column>
            <table-column
              v-for="(col, colindex) in colList"
              :key="col.id || colindex"
              :data="col"
              width="200"
            >
              <template v-slot="{ row, column }">
                {{ row | setField(column.field) }}
              </template>
            </table-column>
          </en-table>
        </div>
      </div>
    </div>
    <filterComm
      ref="popFilter"
      :fieldList="colList"
      :searchParams="conditions"
      :setBaseData="setBaseData"
      @filterSave="filterSave"
      @showBaseData="showBaseData"
    ></filterComm>
    <alertCompare
      ref="popCompare"
      :templateId="templateId"
      :btnData="btnData"
    ></alertCompare>
    <baseDataComm
      ref="popBaseData"
      :filterRowData="filterRowData"
      @saveBaseData="saveBaseData"
    ></baseDataComm>
    <alertExport
      ref="popExport"
      @toDownloadCenter="$refs.downDialog.open()"
      :templateId="templateId"
      :exportData="exportData"
    ></alertExport>
    <!-- 下载 -->
    <enBusinessDownDialog ref="downDialog"></enBusinessDownDialog>
    <businessDialog
      v-if="businessDialogShow"
      :businessParams="businessParams"
      :dialogVisible.sync="businessDialogShow"
    >
    </businessDialog>
    <businessSearchDialog
      @confirmSearch="filterSave"
      :search-field-list="colList"
      ref="searchDialog"
    >
    </businessSearchDialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { request } from "en-js";
import { cloneDeep } from "lodash";
import { defaultListService } from "@/api";
import { viewBusinessDialogParams } from "@/components/businessDetail/helper.js";
import tableColumn from "@/components/en-table-extra/tableColumn";
import businessDialog from "@/components/businessDetail/businessDialog";
import { BusinessDataOpenType } from "@/components/businessDetail/data/BusinessCommonHeader";
import btnComm from "../components/btnComm";
import shareComm from "../components/shareComm";
import viewComm from "../components/viewComm";
import themeViewComm from "../components/themeViewComm";
import filterComm from "../components/filterComm";
import baseDataComm from "../components/selectBaseData";
import alertExport from "../components/alertExport";
import alertCompare from "../components/alertCompare";

export default {
  name: "viewDefault",
  components: {
    btnComm,
    // shareComm,
    // viewComm,
    themeViewComm,
    filterComm,
    baseDataComm,
    tableColumn,
    alertExport,
    alertCompare,
    businessDialog
  },
  data() {
    return {
      tabeHeight: 0,
      enloading: false,
      templateId:
        this.$route.query.templateId || this.$attrs.menuData?.refData?.id, // 模板id
      templateName:
        this.$route.query.templateName || this.$attrs.menuData?.refData?.name, // 模板名称
      viewId: this.$route.query.viewId || this.$attrs.menuData?.refData?.viewId, // 视图id
      batchBtnList: [], // 顶部按钮数据集合
      tabList: [], // tab页签
      activeTab: "", // 当前激活tab页面id
      activeIndex: 0,
      transPosition: 0, // 初始位置
      step: 200, // 移动的步长
      singleBtnList: [], // 列表中按钮数据集合
      colList: [], // 列投数据集合
      dataType: 0, // 1.基础数据 2.业务建模
      conditions: [], // 请求条件
      setBaseData: {}, // 类型为5时选择基础数据返回值回调填充筛选弹窗
      sortField: {}, // 排序字段
      isDefault: "",
      defaultData: {}, // 查询列表返回的数据
      defaultList: [], // 列表数据
      pageModel: {}, // 分页配置
      supernatantFields: [],
      shareUserId: "", // 共享人id
      filterRowData: {}, // 过滤的某一行数据
      btnData: {}, // 按钮数据
      has: false, // 穿透条件展示,
      exportData: {}, // 导出参数
      businessDialogShow: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$attrs);
      this.queryTabs();
    });
  },
  filters: {
    /**
     * @description: 格式化单元格
     * @param {*} item
     * @param {*} field
     * @return {*}
     */
    setField(item, field) {
      const colItem = this.colList.find((a) => a.field === field);
      const colType = colItem?.fieldType;
      const value = item[field];
      let str = "";
      if (colType === 5 || colType === 15 || colType === 33) {
        if (value.length) {
          str = value.map((ele) => ele.name).join(",");
        }
      } else if (colType === 29) {
        if (value.provinceName) {
          str += value.provinceName;
        }
        if (value.cityName) {
          str += value.cityName;
        }
        if (value.districtName) {
          str += value.districtName;
        }
        if (value.address) {
          str += value.address;
        }
      } else {
        str = value;
      }
      return str;
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    refData() {
      return this.$attrs.menuData?.refData;
    }
  },
  methods: {
    // 返回按钮点击
    handleBackIconClick() {
      this.$router.back();
    },
    // 筛选
    handleFilterSortChange({ filterData, sortData }) {
      this.pageModel.pageNo = 1;
      const filter = JSON.parse(filterData || "[]");
      // 重复条件替换 zjh
      if (filter.length) {
        filter.forEach((element) => {
          let flg = false;
          this.conditions.forEach((element1, index1) => {
            if (element1.nameVariable === element.nameVariable) {
              flg = true;
              this.conditions[index1] = element;
            }
          });
          if (!flg) {
            this.conditions.push(element);
          }
        });
      } else {
        // 清除条件 filter为空数组
        this.conditions = [];
      }
      // this.conditions = [...this.conditions, ...filter];
      // 不含有 opType 操作符的 文本类型 需要操作符才可以使用 zjh
      this.conditions.forEach((element) => {
        if (
          ["1", "2"].indexOf(String(element.fieldType) > -1)
          && !element.opType
        ) {
          element.opType = 3;
        }
        element.field = element.nameVariable;
      });
      this.sortField = JSON.parse(sortData || "{}");
      this.queryDetailResult();
    },
    /**
     * @description: 切换tab页签
     * @param {*} item
     * @return {*}
     */
    changeTabs(item, index) {
      this.activeIndex = index;
      this.colList = [];
      this.defaultList = [];
      this.activeTab = item.id;
      this.changeConditions();
      this.queryListCol();
    },
    // 移动页签
    moveLeft() {
      if (this.transPosition < 0) {
        this.transPosition += this.step;
      }
    },
    moveRight() {
      const contentWidth = this.$refs.content.offsetWidth;
      const elementWidth = this.$refs.inner.offsetWidth;
      this.maxPosition = elementWidth - contentWidth;
      console.log(this.maxPosition, "maxPosition");
      if (this.transPosition >= -this.maxPosition) {
        this.transPosition -= this.step;
      }
    },
    /**
     * @description: 查询视图tab页签
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async queryTabs() {
      const pa = {
        templateId: this.templateId,
        isPreview: 1,
        viewId: this.viewId
      };
      const rsp = await defaultListService.queryTabs(pa);
      this.tabList = rsp;
      this.activeTab = rsp[0]?.id;
      this.changeConditions();
      this.queryListCol();
    },
    // 根据页签获取管理端默认筛选条件
    changeConditions() {
      this.conditions = [];
      const arr = this.tabList.find((item) => item.id === this.activeTab);
      if (arr?.tabRange) {
        const ranges = JSON.parse(arr.tabRange);
        ranges.map((item, ind) => {
          item.name = item.fieldName;
          item.field = item.nameVariable;
          return ind;
        });
        this.conditions = ranges;
      }
      this.setCondition(this.conditions);
    },
    setCondition(searchParams) {
      const tableRef = this.$refs.viewTable;
      if (!tableRef) return;
      tableRef.filterStore.dataList = searchParams.map((item) => ({
        ...item,
        field: item.nameVariable,
        prop: item.nameVariable,
        property: item.nameVariable,
        type: "filter",
        value: item.value,
        valueText: item.valueText,
        sort: item.sort,
        opType: item.opType,
        sortType: item.sortType,
        fieldType: item.fieldType,
        column: {
          opType: item.opType,
          opTypeText: item.name,
          title: item.fieldName,
          field: item.nameVariable,
          property: item.nameVariable,
          prop: item.nameVariable
        }
      }));
    },
    /**
     * @description: 查询列头、按钮 、样式等
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async queryListCol() {
      const pa = {
        templateId: this.templateId,
        isPreview: 1,
        viewId: this.viewId,
        tabId: this.activeTab
      };
      const rsp = await defaultListService.queryListCol(pa);
      this.batchBtnList = rsp.batchBtnList || [];
      this.singleBtnList = rsp.singleBtnList || [];
      this.singleBtnList.push({
        btnKey: "delete",
        btnName: "删除",
        colour: "",
        dataRange: "数据范围",
        funcType: "1",
        icon: "0"
      });
      // 列头数据
      this.colList = rsp.colList || [];
      // 1.基础数据 2.业务建模
      this.dataType = rsp.type || 0;
      // const cons = rsp.conditions || [];
      // console.log("cons", cons);
      // this.conditions = cons.filter((item) => item.nameVariable !== "finishType" && item.nameVariable !== "secretType");
      this.isDefault = rsp.isDefault;
      // zjh 看板穿透
      if (String(this.$route.query.screen) === "6") {
        this.conditions = [];
        const list = JSON.parse(sessionStorage.getItem("business_condition"))?.dataList
          || [];
        list.forEach((element) => {
          element.nameVariable = element.fieldNameVariable;
          element.field = element.fieldNameVariable;
          element.name = element.fieldNameVariable;
        });
        this.conditions = list;
        this.setCondition(this.conditions);
        this.conditions.forEach((element) => {
          if (
            ["1", "2"].indexOf(String(element.fieldType) > -1)
            && !element.opType
          ) {
            element.opType = 3;
          }
          element.field = element.nameVariable;
        });
      }
      this.queryDetailResult();
    },
    /**
     * @description: 查看列表数据
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async queryDetailResult() {
      const pa = {
        templateId: this.templateId,
        isPreview: 1,
        viewId: this.viewId,
        tabId: this.activeTab,
        pageSize: this.pageModel.pageSize || 10,
        pageNo: this.pageModel.pageNo || 1,
        filterFields: JSON.stringify(this.conditions),
        sortField: JSON.stringify(this.sortField),
        shareUserId: this.shareUserId
      };
      const rsp = await defaultListService.queryDetailResult(pa);
      const obj = rsp || {};
      this.defaultData = obj;
      this.defaultList = this.defaultData.records;
      this.pageModel = {
        pageNo: this.defaultData.pageNo || 1,
        pageSize: this.defaultData.pageSize || 10,
        total: this.defaultData.total || "",
        totalPages:
          this.defaultData.totalPages
          || Math.ceil(this.defaultData.total / (this.defaultData.pageSize || 10))
      };
      // // zjh 看板穿透 设置条件
      if (String(this.screen) === "6") {
        this.$nextTick(() => {
          if (this.has) return;
          this.$refs.viewTable.filterStore.dataList = JSON.parse(sessionStorage.getItem("business_condition"))
              ?.dataList || [];
          this.has = true;
        });
      }
    },
    /**
     * @description: 分页回调
     * @param {*}
     * @return {*}
     */
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.pageModel.pageSize = pageSize;
      this.pageModel.pageNo = pageNo;
      this.queryDetailResult();
    },
    /**
     * @description: 行按钮
     * @param {*}
     * @return {*}
     */
    /**
     * @description
     * @param 表格操作列按钮
     */
    buttonData({ row }) {
      const btnList = cloneDeep(this.singleBtnList);
      const resBtn = [];
      const createId = row.createId.length && row.createId[0].id;

      if (row.addType !== 2) {
        btnList.pop();
        btnList.filter((item) => item.btnKey !== "delete");
      }
      if (createId === this.userInfo.userId) {
        btnList.forEach((item) => {
          if (item.btnKey === "encrypt") {
            if (row.secretType === 1) {
              resBtn.push({
                icon: "iconsimi",
                id: "btn-simi-cancel",
                name: `取消${item.btnName}`,
                iconColor: "#aeaeae"
              });
            } else {
              resBtn.push({
                icon: "iconsimi",
                id: "btn-simi",
                name: item.btnName,
                iconColor: "#4793de"
              });
            }
          } else if (item.btnKey === "finish") {
            if (row.finishType === 1) {
              resBtn.push({
                icon: "iconwancheng",
                id: "btn-wancheng-cancel",
                name: `取消${item.btnName}`,
                iconColor: "#aeaeae"
              });
            } else {
              resBtn.push({
                icon: "iconwancheng",
                id: "btn-wancheng",
                name: item.btnName,
                iconColor: "#4793de"
              });
            }
          } else {
            resBtn.push({
              icon: "iconshanchu-liebiao",
              id: "btn-shanchu",
              name: "删除",
              iconColor: "#fa6a6a"
            });
          }
        });
      }
      return resBtn;
    },
    /**
     * @description
     * @param 表格操作列按钮事件
     */
    buttonClick({ row, index, btnData }) {
      console.log(index);
      switch (btnData.id) {
        case "btn-wancheng": // 完成
          this.wanchengRow(row, btnData.name);
          break;
        case "btn-wancheng-cancel": // 取消完成
          this.wanchengCancelRow(row, btnData.name);
          break;
        case "btn-simi": // 私密
          this.simiRow(row, btnData.name);
          break;
        case "btn-simi-cancel": // 取消私密
          this.simiCancelRow(row, btnData.name);
          break;
        case "btn-shanchu": // 删除
          this.deleteRow(row, btnData.name);
          break;
        default:
          break;
      }
    },
    /**
     * @description: 完成
     * @param {*} row
     * @param {*} index
     * @return {*}
     */
    @request(true, "enloading")
    async wanchengRow(row, btnName, type) {
      const params = {
        templateId: this.templateId,
        id: row.id,
        refType: row.refType,
        finishType: type || 1
      };
      await defaultListService.ptFinish(params);
      this.$message(`${btnName}成功`);
      setTimeout(() => {
        this.queryDetailResult();
      }, 500);
    },
    /**
     * @description: 取消完成
     * @param {*} row
     * @return {*}
     */
    wanchengCancelRow(row, btnName) {
      this.wanchengRow(row, btnName, 2);
    },
    /**
     * @description: 私密
     * @param {*} row
     * @return {*}
     */
    @request(true, "enloading")
    async simiRow(row, btnName, type) {
      const params = {
        templateId: this.templateId,
        id: row.id,
        refType: row.refType,
        secretType: type || 1
      };
      await defaultListService.ptSecret(params);
      this.$message(`${btnName}成功`);
      setTimeout(() => {
        this.queryDetailResult();
      }, 500);
    },
    /**
     * @description: 取消私密
     * @param {*} row
     * @return {*}
     */
    simiCancelRow(row, btnName) {
      this.simiRow(row, btnName, 2);
    },
    /**
     * @description: 删除
     * @param {*} row
     * @return {*}
     */
    deleteRow(row) {
      this.$confirm("确定删除已选择的1条数据", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        defaultListService.ptOut({ templateId: this.templateId, id: row.id });
        this.$message("删除成功");
        setTimeout(() => {
          this.queryDetailResult();
        }, 500);
      });
    },
    /**
     * @description: 分享回调
     * @param {*} share
     * @return {*}
     */
    saveCallback(share) {
      this.shareUserId = share.shareUserId;
      this.queryDetailResult();
    },
    /**
     * @description: 过滤按钮回调
     * @param {*}
     * @return {*}
     */
    filterChange() {
      // this.$refs.popFilter.visiable = true;
      this.$refs.searchDialog.open();
    },
    /**
     * @description: 导出按钮回调
     * @param {*}
     * @return {*}
     */
    exportChange() {
      this.$refs.popExport.visible = true;
      const {
        templateId,
        activeTab,
        shareUserId,
        conditions,
        colList,
        sortField
      } = this;
      this.exportData = {
        templateId,
        activeTab,
        shareUserId,
        filterFields: conditions,
        sortField,
        showList: colList
      };
    },
    /**
     * @description: 对比按钮回调
     * @param {*}
     * @return {*}
     */
    compareChange(btnData) {
      this.$refs.popCompare.visiable = true;
      this.btnData = btnData;
    },
    /**
     * @description: 选择基础数据
     * @param {*}
     * @return {*}
     */
    showBaseData(row) {
      this.$refs.popBaseData.visiable = true;
      this.filterRowData = row;
    },
    /**
     * @description:基础数据内容保存
     * @param {*}
     * @return {*}
     */
    saveBaseData(par) {
      this.setBaseData = par;
    },
    /**
     * @description: 过滤弹窗回调
     * @param {*} cons
     * @return {*}
     */
    filterSave(re) {
      this.conditions = JSON.parse(re.searchParams || JSON.stringify([]));
      this.setCondition(this.conditions);
      this.queryDetailResult();
    },
    /**
     * @description: 查看业务建模详情
     * @param {*} cons
     * @return {*}
     */
    handleRowClick({ row }) {
      const { refType, businessType } = row;
      if ([3, 22].includes(refType)) {
        let params = {
          templateId: this.templateId,
          businessType,
          openType: BusinessDataOpenType.view,
          originOpenBusinessType: "theme"
        };
        params = Object.assign(params, { id: row.businessKey });
        this.businessParams = viewBusinessDialogParams(params);
        this.businessDialogShow = true;
      } else if ([20, 21].includes(refType)) {
        // 待办 已办
        if (["1", "2"].includes(businessType)) {
          let params = {
            templateId: this.templateId,
            businessType,
            openType: BusinessDataOpenType.view,
            originOpenBusinessType: "theme"
          };
          params = Object.assign(params, { id: row.businessKey });
          this.businessParams = viewBusinessDialogParams(params);
          this.businessDialogShow = true;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.taglis {
  padding: 14px 20px;

  .el-tag {
    margin-right: 5px;
  }
}

.theme-set {
  background: #fff;

  .topbar {
    height: 55px;
    border-bottom: 1px solid #eaebf0;
    padding: 0 10px;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .pull-center {
      position: absolute;
      width: 200px;
      left: 50%;
      margin-left: -100px;
      line-height: 50px;
      font-size: 14px;
    }

    .pull-left {
      font-size: 12px;
      display: flex;
      justify-content: center;
      .col-names {
        line-height: 34px;
        margin-left: 8px;
        color: #585858;
      }

      .en-select {
        margin-left: 8px;
      }
    }

    .pull-right {
      padding-top: 8px;
    }

    .back-btn {
      display: inline-block;
      vertical-align: middle;
      color: #aeaeae;
    }
  }

  .centbar {
    // padding-top: 10px;
    height: calc(100% - 60px);
  }
}

.view-table {
  // height: calc(100% - 50px);
  height: 100%;
  margin-top: -1px;

  .table-cent {
    height: calc(100% - 60px);
  }
}

/deep/ .en-tree-node .en-tree-node__label-wrapper {
  max-width: 300px;
}

.pull-right {
  padding: 0px;
  display: flex;
  align-items: center;
}
.pull-left {
  display: flex;
  justify-content: left;
  align-items: center;
  .en-icon {
    color: #a9b5c6;
    cursor: pointer;
    vertical-align: middle;
  }

  .col-names {
    vertical-align: middle;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 50px;
    margin-right: 10px;
    font-weight: bold;
  }
  .header-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    background: linear-gradient(-30deg, #3e90fe, #5fa5ff);
    border-radius: 8px;
    margin-left: 6px;

    .en-icon {
      color: #fff;
    }
  }

  .title-text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #1a1c1e;
  }
}
.button-container {
  user-select: none;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 45vw;
  height: 100%;
}

.left-arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .arr-icon {
    color: #aab6c7;
    &:hover {
      color: #3e90fe !important;
    }
  }
}
.right-arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .right-icon {
    color: #aab6c7;
    &:hover {
      color: #3e90fe !important;
    }
  }
}

.content {
  // margin-left:35px;
  display: flex;
  height: 100%;
  width: 40vw;
  overflow: hidden;
}

.inner {
  display: flex;
  transition: transform 0.3s ease;
  align-items: center;
}

.tab-filter-button {
  height: 32px;
  background: #ffffff;
  border: 1px solid #e8ecf2;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #1a1c1e;
  cursor: pointer;
  line-height: 32px;
  margin-right: 20px;
  padding: 0px 23px;
  text-align: center;
  white-space: nowrap;
}
.tab-filter-button:hover {
  background: #71b7f8;
  color: #ffffff;
}
.active {
  background: #3e90fe;
  color: #ffffff;
}
.fst-button {
  background: #3e90fe;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
</style>
